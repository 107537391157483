import Home from "./pages/general/home/Home";
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import OrderSingle from "./pages/ecommerce/orderSingle/OrderSingle";
import ProductNew from "./pages/ecommerce/productNew/ProductNew";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext, useEffect, useState } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from ".//firebaseconfig";
import { Center, ChakraProvider, Spinner } from "@chakra-ui/react";
import Authorization from "./pages/ecommerce/authorization/Authorization";
import { UserContext } from "./UserContext";
import { arrayUnion, collection, doc, getDocs, limit, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import Product from "./pages/ecommerce/product/Product";
import Report from "./pages/ecommerce/report/Report";
import Feedback from "./pages/feedback/Feedback";
import FeedbackSingle from "./pages/feedbackSingle/FeedbackSingle";
import Account from "./pages/account/Account";
import DashboardHome from "./pages/dashboardHome/DashboardHome";
import Terms from "./pages/general/terms/Terms";
import ContactUs from "./pages/general/contactUs/ContactUs";
import Privacy from "./pages/general/privacy/Privacy";
import Login from "./pages/general/login/Login";
import AboutUs from "./pages/general/aboutUs/AboutUs";
import Blog from "./pages/general/blog/Blog";
import BarcodeScanner from "./pages/ecommerce/barcodeScanner/BarcodeScanner";
import BarcodeScannerGroup from "./pages/ecommerce/barcodeScanner/BarcodeScannerGroup";
import Questions from "./pages/general/questions/Questions";
import Tags from "./pages/general/questions/Tags";
import AskQuestion from "./pages/general/questions/AskQuestion";
import SingleQuestions from "./pages/general/questions/SingleQuestions";
import SingleBlog from "./pages/general/blog/SingleBlog";
import Shipment from "./pages/ecommerce/shipment/shipment";
import ShipmentPrintAWB from "./pages/ecommerce/shipment/print_awb";
import Services from "./pages/general/services/Services";
import convertToNearestDay from "./utils/convertToNearestDay";
import * as ShopeeFunctions from "./utils/shopee";
import * as LazadaFunctions from "./utils/lazada";

function App() {

  const { darkMode } = useContext(DarkModeContext);
  var darkModeBoolean = (darkMode === 'true');

  const [user, loading, error] = useAuthState(auth);
  const [shopList, setShopList] = useState([]);
  const [shopeeShopList, setShopeeShopList] = useState([]);
  const [lazadaShopList, setLazadaShopList] = useState([]);
  const [userMetadata, setUserMetaData] = useState({});
  const [shopeeOrders, setShopeeOrders] = useState([]);
  const [lazadaOrders, setLazadaOrders] = useState([]);
  const [randomNumber, setRandomNumber] = useState(0);

  const twoDaysAgo = new Date(convertToNearestDay(new Date().valueOf() / 1000 - 86400 * 2, 8) * 1000);
  const startDate = convertToNearestDay(twoDaysAgo / 1000, 8);

  useEffect(() => {
    if (user === null)
      return;

    function retrieveUserMetaData() {
      const userCollection = collection(db, "user");
      const userQuery = query(userCollection, where("uid", "==", user.uid), limit(1));

      // Only will have 1 user
      // So just replace the entire user for each changes
      onSnapshot(userQuery, (userSnapshot) => {
        userSnapshot.docChanges().forEach((userChange) => {
          var tempArray = [];
          for (let i = 0; i < userChange.doc.data().notification.length; i++) {

            var tempNotification = userChange.doc.data().notification[i];
            tempNotification.key = i;
            tempArray.push(tempNotification);
          }
          var userData = userChange.doc.data();
          userData.notification_array = tempArray;
          setUserMetaData(userData);
        })
      }
      );
    }

    retrieveUserMetaData();

    async function getShops() {

      const shopDocsCollection = collection(db, "ecommerce/auth/shop");
      const shopDocsQuery = query(shopDocsCollection, where("access_by", "array-contains-any", [user.uid]));

      var shopListArray = [];
      onSnapshot(shopDocsQuery, (shopSnapshot) => {
        shopSnapshot.docChanges().forEach((shopChange) => {
          shopListArray.push(shopChange.doc.data());
          setRandomNumber(Math.random());
          setShopList(shopListArray);
        })
      })
    }

    getShops();

  }, [user]);

  useEffect(() => {

    var shopeeShopListArray = [];
    var lazadaShopListArray = [];

    for (let i = 0; i < shopList.length; i++) {
      switch (shopList[i].platform) {
        case "Shopee":
          shopeeShopListArray.push(shopList[i].shop_id);
          break;
        case "Lazada":
          lazadaShopListArray.push(shopList[i].shop_id);
          break;
      }
    }

    setShopeeShopList(shopeeShopListArray);
    setLazadaShopList(lazadaShopListArray);
  }, [shopList, randomNumber]);

  useEffect(() => {
    if (shopeeShopList.length === 0)
      return;
    async function getShopeeOrders() {

      const shopeeOrderDocsCollection = collection(db, "ecommerce/shopee/order");
      var shopeeOrdersQuery = query(shopeeOrderDocsCollection,
        where("shop_id", "in", shopeeShopList),
        where("create_time", ">=", startDate),
        orderBy("create_time", "desc"));

      var tempShopeeOrders = [];
      onSnapshot(shopeeOrdersQuery, async (orderSnapshot) => {
        if (orderSnapshot.size === 0)
          return;

        var currentShop;
        orderSnapshot.docChanges().forEach((orderChange) => {
          for (let index = 0; index < shopList.length; index++) {
            if (shopList[index].shop_id === orderChange.doc.data().shop_id) {
              currentShop = shopList[index];
              break;
            }
          };

          var currentOrder = orderChange.doc.data();
          
          var trackingNumber = "";
          if (orderChange.doc.data().order_status === "PROCESSED" &&
            (typeof (orderChange.doc.data().logistic_details.tracking_number) === "undefined" || orderChange.doc.data().logistic_details.tracking_number === "")) {
            const logisticTimestamp = Math.floor(new Date().getTime() / 1000);
            const logisticSign = ShopeeFunctions.Signature(logisticTimestamp, "/api/v2/logistics/get_tracking_number",
              currentShop.access_token, currentShop.shop_id);

            const request = new XMLHttpRequest();
            request.open("GET", "https://partner.shopeemobile.com/api/v2/logistics/get_tracking_number?" +
              "partner_id=2004820" +
              "&shop_id=" + currentShop.shop_id +
              "&access_token=" + currentShop.access_token +
              "&timestamp=" + logisticTimestamp +
              "&sign=" + logisticSign +
              "&order_sn=" + orderChange.doc.data().order_id, false);  // `false` makes the request synchronous
            request.send(null);
            if (request.status !== 200) {

            }
            const data = JSON.parse(request.responseText);
            trackingNumber = data.response.tracking_number;
            currentOrder.logistic_details = data.response;


            var tracking_number_array = [""];
            if (typeof (trackingNumber) !== 'undefined' &&
              trackingNumber !== "") {
              for (let j = 3; j < trackingNumber.length; j++) {
                for (let k = 0; k < trackingNumber.length; k++) {
                  if (k + j <= trackingNumber.length)
                    tracking_number_array.push(currentShop.shop_id + "_" + trackingNumber.substring(k, k + j));
                }
              };
              tracking_number_array.push(currentShop.shop_id + "_" + trackingNumber);
            };

            setDoc(doc(db, "ecommerce/shopee/order", String(orderChange.doc.data().order_id)),
              {
                tracking_number_array: arrayUnion(...tracking_number_array),
                logistic_details: data.response,
                updated_at: new Date()
              }, {
              merge: true
            });
          }

          const findIndex = tempShopeeOrders.findIndex(obj => obj.order_id === currentOrder.order_id);
          if (findIndex === -1) {
            tempShopeeOrders.push(currentOrder);
          }
          else {
            tempShopeeOrders[findIndex] = currentOrder;
          }
          setShopeeOrders(tempShopeeOrders);
        });
      }
      );

    }
    getShopeeOrders();
  }, [shopeeShopList]);

  useEffect(() => {
    if (lazadaShopList.length === 0)
      return;

    async function getLazadaOrders() {
      const lazadaOrderDocsCollection = collection(db, "ecommerce/lazada/order");

      var lazadaOrdersQuery = query(lazadaOrderDocsCollection,
        where("shop_id", "in", lazadaShopList),
        where("create_time", ">=", startDate),
        orderBy("create_time", "desc"));

      var tempLazadaOrders = [];
      onSnapshot(lazadaOrdersQuery, async (orderSnapshot) => {

        orderSnapshot.docChanges().forEach((orderChange) => {

          var currentOrder = orderChange.doc.data();
          var shopName = "";
          for (let index = 0; index < shopList.length; index++) {
            if (shopList[index].shop_id === orderChange.doc.data().shop_id) {
              shopName = shopList[index].name;
              break;
            }
          };


          var orderStatusString = orderChange.doc.data().item_details[0].status;
          for(let j = 1; j < orderChange.doc.data().item_details.length; j++)
          {
            if(orderStatusString.includes(orderChange.doc.data().item_details[j].status) === false)
            {
              orderStatusString += ", " + orderChange.doc.data().item_details[j].status;
            }
          }

          var trackingNumberString = "";
          var trackingNumberArray = [];
          var shipment_provider = "";
          for(let j = 0; j < orderChange.doc.data().order_item_id_details.length; j++)
          {
            if (trackingNumberArray.includes(orderChange.doc.data().order_item_id_details[j].tracking_code) === false) {
              if (trackingNumberString !== "") {
                trackingNumberString += ", " + orderChange.doc.data().order_item_id_details[j].tracking_code
              }
              else {
                trackingNumberString = orderChange.doc.data().order_item_id_details[j].tracking_code;
              }

              trackingNumberArray.push(orderChange.doc.data().order_item_id_details[j].tracking_code);
            }

              if(typeof (orderChange.doc.data().order_item_id_details[j].shipment_provider) !== "undefined" 
              && orderChange.doc.data().order_item_id_details[j].shipment_provider !== ""){
                  var tempShipmentProvider = "";
                  if (orderChange.doc.data().order_item_id_details[j].shipment_provider.includes("Pickup:")) {
                      tempShipmentProvider = orderChange.doc.data().order_item_id_details[j].shipment_provider.split(',')[0].split(':')[1].trim();
                  }

                  if(shipment_provider.includes(tempShipmentProvider) === false)
                  {
                      if(shipment_provider === "")
                      {
                          shipment_provider = tempShipmentProvider;
                      }
                      else
                      {
                          shipment_provider += ", " + tempShipmentProvider;
                      }
                  }
              } 
          }

          if(shipment_provider === "")
              shipment_provider = "Pending";

          currentOrder.shipment_provider = shipment_provider;
          currentOrder.tracking_number = trackingNumberString;
          currentOrder.order_status = orderStatusString;

          if(typeof(currentOrder.message_to_seller) === "undefined")
          {
            currentOrder.message_to_seller = "";
          }
          if(typeof(currentOrder.note) === "undefined")
          {
            currentOrder.note = "";
          }

          const findIndex = tempLazadaOrders.findIndex(obj => obj.shop_id === currentOrder.shop_id && obj.order_id === currentOrder.order_id);
          if (findIndex === -1) {
            tempLazadaOrders.push(currentOrder);
          }
          else {
            tempLazadaOrders[findIndex] = currentOrder;
          }

          setLazadaOrders(tempLazadaOrders);
        });

      }
      );

    }
    getLazadaOrders();
  }, [lazadaShopList]);

  if (loading) {
    return (
      <ChakraProvider>
        <Center h="100vh">
          <Spinner />
        </Center>
      </ChakraProvider>)
  }

  if (!user) {
    return (
      <div style={{ backgroundColor: "white", width: "100%", height: "100%" }}>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Navigate replace to="/" />} />
            <Route path="/">
              <Route index element={<Home active="middleHomeContainer" />} />
            </Route>
            <Route path="/questions">
              <Route index element={<Questions active="questionsContainer" />} />
              <Route path=":questionId/:title" element={<SingleQuestions active="questionsContainer" />} />
            </Route>
            <Route path="/tags">
              <Route index element={<Tags active="questionsContainer" userMetadata={userMetadata} />} />
            </Route>
            <Route path="/about_us">
              <Route index element={<AboutUs active="aboutUsContainer" />} />
            </Route>
            <Route path="/services">
              <Route index element={<Services active="servicesContainer" />} />
            </Route>
            <Route path="/terms">
              <Route index element={<Terms active="termsContainer" />} />
            </Route>
            <Route path="/contact_us">
              <Route index element={<ContactUs active="contactUsContainer" />} />
            </Route>
            <Route path="/blog">
              <Route index element={<Blog active="blogContainer" />} />
            </Route>
            <Route path="/blogs">
              <Route path=":categoryId/:blogId" element={<SingleBlog />} />
            </Route>
            <Route path="/privacy">
              <Route index element={<Privacy active="privacyContainer" />} />
            </Route>
            <Route path="/login">
              <Route index element={<Login active="loginSignupContainer" />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>)
  }

  return (
    <div className={darkModeBoolean ? "app dark" : "app"}>
      <UserContext.Provider value={{ shopList }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" >
              <Route path="/login" element={<Navigate replace to="/" />} />
              <Route path="/contact_us" element={<Navigate replace to="/feedback" />} />
              <Route path="/*" element={<Navigate replace to="/" />} />

              <Route path="/services">
                <Route index element={<Services active="servicesContainer" userMetadata={userMetadata} />} />
              </Route>
              <Route path="/blog">
                <Route index element={<Blog active="blogContainer" userMetadata={userMetadata} />} />
              </Route>
              <Route path="/blogs">
                <Route path=":categoryId/:blogId" element={<SingleBlog userMetadata={userMetadata} />} />
              </Route>
              <Route path="/questions">
                <Route index element={<Questions active="questionsContainer" userMetadata={userMetadata} />} />
                <Route path=":questionId/:title" element={<SingleQuestions userMetadata={userMetadata} />} />
                <Route path="ask">
                  <Route index element={<AskQuestion active="questionsContainer" userMetadata={userMetadata} />} />
                </Route>
                <Route path="edit">
                  <Route path=":questionId" element={<AskQuestion userMetadata={userMetadata} />} />
                  <Route path=":questionId/:answerId" element={<AskQuestion userMetadata={userMetadata} />} />
                </Route>
              </Route>
              <Route path="/tags">
                <Route index element={<Tags active="questionsContainer" userMetadata={userMetadata} />} />
              </Route>
              <Route path="/about_us">
                <Route index element={<AboutUs active="aboutUsContainer" />} />
              </Route>
              <Route path="/services">
                <Route index element={<Services active="servicesContainer" />} />
              </Route>
              <Route path="/terms">
                <Route index element={<Terms active="termsContainer" />} />
              </Route>
              <Route path="/privacy">
                <Route index element={<Privacy active="privacyContainer" />} />
              </Route>
              <Route index element={<DashboardHome key={typeof(shopeeOrders.length) + typeof(lazadaOrders.length)} 
              userMetadata={userMetadata} shopeeOrders={shopeeOrders} lazadaOrders={lazadaOrders}/>} />
              <Route path="users">
                <Route path=":userId" element={<OrderSingle />} />
                <Route
                  path="new"
                  element={<ProductNew title="Add New User" inputs={userInputs} />}
                />
              </Route>
              <Route path="balance">
                <Route path=":balanceId" element={<OrderSingle />} />
                <Route
                  path="new"
                  element={<ProductNew title="Latest Transactions" inputs={userInputs} />}
                />
              </Route>
              <Route path="sales">
                <Route path=":salesId" element={<OrderSingle />} />
                <Route
                  path="new"
                  element={<ProductNew title="Add New Sales" inputs={userInputs} />}
                />
              </Route>
              <Route path="account">
                <Route index element={<Account type="account" userMetadata={userMetadata} />} />
              </Route>
              <Route path="authorization">
                <Route index element={<Authorization type="authorization" userMetadata={userMetadata} />} />
                <Route path=":authorizationId" element={<OrderSingle />} />
                <Route
                  path="new"
                  element={<ProductNew title="Add New Authorization" inputs={productInputs} />}
                />
              </Route>

              <Route path="barcode_scanner">
                <Route index element={<BarcodeScanner userMetadata={userMetadata} />} />
                <Route path="group">
                  <Route path=":groupId" element={<BarcodeScannerGroup userMetadata={userMetadata} />} />
                </Route>
              </Route>
              <Route path="feedback">
                <Route index element={<Feedback type="feedback" userMetadata={userMetadata} />} />
                <Route path=":feedbackId" element={<FeedbackSingle userMetadata={userMetadata} />} />
                <Route
                  path="new"
                  element={<ProductNew title="Add New Feedback" inputs={productInputs} />}
                />
              </Route>
              <Route path="products">
                <Route index element={<Product type="products" userMetadata={userMetadata} />} />
                <Route path=":productId" element={<OrderSingle />} />
                <Route
                  path="new"
                  element={<ProductNew title="Add New Product" inputs={productInputs} userMetadata={userMetadata} />}
                />
                <Route
                  path="edit">
                  <Route path=":sku" element={<ProductNew title="Add New Orders" inputs={userInputs} userMetadata={userMetadata} />} />
                </Route>
              </Route>
              {
                /*
              <Route path="shipment">
                <Route index element={<Shipment shopList={shopList} 
              userMetadata={userMetadata} shopeeOrders={shopeeOrders} lazadaOrders={lazadaOrders}/>} />
                <Route
                  path="print_awb"
                  element={<ShipmentPrintAWB shopList={shopList} userMetadata={userMetadata} />}
                />
              </Route>
                */
              }
              <Route path="reports/profit_loss">
                <Route index element={<Report shopList={shopList} userMetadata={userMetadata} />} />
              </Route>
              <Route path="reports/orders">
                <Route index element={<Report shopList={shopList} userMetadata={userMetadata} />} />
                <Route path=":orderId" element={<OrderSingle userMetadata={userMetadata} />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
}

export default App;
