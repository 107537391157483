import "./navbar.scss";
import LanguageIcon from '@mui/icons-material/Language';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../firebaseconfig";
import { doc, setDoc } from "firebase/firestore";
import { Link } from "react-router-dom";

const Navbar = ({ userMetadata }) => {
    const { dispatch } = useContext(DarkModeContext);
    const [user] = useAuthState(auth);

    const [openNotification, setOpenNotification] = useState("collapse");
    const [notificationData, setNotificationData] = useState([]);
    const [notificationDataLength, setNotificationDataLength] = useState(0);

    useEffect(() => {
        if (typeof (userMetadata.notification_array) !== "undefined" && typeof (userMetadata.notification_array.length) === "number" && userMetadata.notification_array.length > 0) {
            setNotificationData(userMetadata.notification_array);

            var unreadNotification = 0;
            for (let i = 0; i < userMetadata.notification_array.length; i++) {
                if (typeof (userMetadata.notification_array[i].seen) === "undefined") {
                    unreadNotification++;
                }
            }
            setNotificationDataLength(unreadNotification);
        }
    }, [userMetadata]);

    async function seenNotification(key) {

        var temp = Object.assign([], notificationData);
        var unreadNotification = 0;
        var anyChanges = false;

        // Seen All
        if (typeof (key) === "undefined") {
            for (let i = 0; i < temp.length; i++) {
                if (typeof (temp[i].seen) === "undefined") {
                    temp[i].seen = true;
                    temp[i].seen_at = new Date();
                    anyChanges = true;
                    continue;
                }
            }
        }
        // Seen Specific Key
        else {
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].key === key && typeof (temp[i].seen) === "undefined") {
                    temp[i].seen = true;
                    temp[i].seen_at = new Date();
                    anyChanges = true;
                    continue;
                }

                if (typeof (temp[i].seen) === "undefined") {
                    unreadNotification++;
                }
            }
        }

        if (anyChanges) {
            const userCollection = doc(db, "user", user.uid);
            await setDoc(userCollection, {
                notification: temp
            }, {
                merge: true
            });
        }

        setNotificationData(temp);
        setNotificationDataLength(unreadNotification);
    }

    function alterNotificationVisibility() {
        if (openNotification === "collapse") {
            setOpenNotification("visible");
        } else {
            setOpenNotification("collapse");
        }
    }

    return (
        <div className="navbar">
            <div className="wrapper">
                {
                    /*
                <div className="search">
                    <input type="text" placeholder="Search..." />
                    <SearchIcon />
                </div>
                    */
                }
                <div style={{float: "left"}}>
                    {
                        /*
                    <h1>Website Under Development</h1>
                        */
                    }
                </div>
                <div className="items">
                    <div className="item">
                        <WbSunnyIcon className="lightmodeicon" style={{ cursor: "pointer", visibility: "collapse" }}
                            onClick={() => dispatch({ type: "LIGHT" })} />
                        <DarkModeIcon className="darkmodeicon" style={{ cursor: "pointer" }}
                            onClick={() => dispatch({ type: "DARK" })} />
                    </div>
                    {
                        /*
                        <div className="item">
                            <LanguageIcon className="icon" />
                        </div>
                        */
                    }

                    <div className="item">
                        <NotificationsNoneIcon className="icon" onClick={() => {
                            alterNotificationVisibility();
                        }} />

                        <div className="notificationContainer" style={{ visibility: openNotification }}
                            onMouseLeave={() => {
                                alterNotificationVisibility();
                            }}>
                            <div>
                                {
                                    notificationData.map(input => (
                                        <Link key={input.key}  to={input.link} style={{textDecoration: "none", cursor: "pointer"}}>
                                            <div className="notificationDetails" onMouseEnter={() => seenNotification(input.key)}>
                                                <div className="left">
                                                    <img
                                                        src={require('../../materials/logo_background.png')}
                                                        alt=""
                                                        className="logo"
                                                    />
                                                </div>
                                                <div className="right">
                                                    <div>
                                                        <label className="notificationTitle">{input.title}</label>
                                                    </div>
                                                    <div>
                                                        <label className="notificationBody">{input.body}</label>
                                                    </div>
                                                    <div>
                                                        <label className="notificationTime">{
                                                            new Date(input.created_at.seconds * 1000).toLocaleString('en-US',
                                                                {
                                                                    timeZone: 'Asia/Kuala_Lumpur',
                                                                    year: 'numeric', month: 'short', day: 'numeric',
                                                                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                                }
                                                            )}</label>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    {
                                                        input.seen ? <DoneAllIcon /> :
                                                            <div className="unseen" style={{ userSelect: "none" }}></div>
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </div>
                            <button onClick={() => seenNotification()}>Mark all as Seen</button>
                        </div>

                        {
                            notificationDataLength === 0 ?
                                <div style={{ userSelect: "none" }} onClick={() => {
                                    alterNotificationVisibility();
                                }}></div>
                                :

                                <div className="counter" style={{ userSelect: "none" }} onClick={() => {
                                    alterNotificationVisibility();
                                }}>{notificationDataLength} </div>
                        }
                    </div>
                    <div className="item">
                        <Link to="/account" style={{textDecoration: "none", verticalAlign: "center", alignContent: "center", marginTop: "auto", marginBottom: "auto", textAlign: "center"}}>
                        <div style={{display: "flex"}}>
                            {
                                String(user.photoURL).length <= 4 ? 
                                <div className="circle">{user.displayName.substring(0,1)}</div>
                                : 
                                <img
                                    src={user.photoURL}
                                    alt=""
                                    className="avatar"
                                    style={{paddingRight: "5px", objectFit: "cover"}}
                                />
                            }
                        <p style={{textAlign: "center", marginTop: "auto", marginBottom: "auto"}}>
                        {
                            user.displayName
                        }
                        </p>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;