// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQKu6jfoTo4qbGCsBI7Dc0IuY_guOXmD0",
  authDomain: "tactibots-1605018905444.firebaseapp.com",
  databaseURL: "https://tactibots-1605018905444-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tactibots-1605018905444",
  storageBucket: "tactibots-1605018905444.appspot.com",
  messagingSenderId: "95028350570",
  appId: "1:95028350570:web:cef90d989909d7dd2c8413",
  measurementId: "G-MB4MLW47L0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth();
const db = getFirestore();

const messaging = getMessaging(app);
var token = "";

onAuthStateChanged(auth, async () => {
  if(token !== "")
  {
    /*
    await setDoc(doc(db, "user", auth.currentUser.uid),{
      token: token,
    }, {
      merge: true
    });
    */
  }

});

function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging,
        { vapidKey: "BB3U3UzgxnQhohEKvZWUETXtg4IG7-vVuGCUQOPH7gcMxzXI3sUshAM1SEFIsl9rLthpwwduAZObwx9THz_Clxo" }
      ).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          token = currentToken;
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
    }
    else {
      console.log("Notification permission failed.");
    }
  })
}
requestPermission();


onMessage(messaging, async (payload) => {

  //console.log("Message received. ", JSON.stringify(payload));
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: '/firebase-logo.png'
  };

  /*
  if (Notification.permission === "granted") {
    const reg = await navigator.serviceWorker.getRegistration('../firebase-messaging-sw.js');
    reg.showNotification(notificationTitle,
      notificationOptions);
 }
 */
});

export { auth, db };